.header-menu-container {
  @apply h-[57px] border-b border-border;
  &-content {
    @apply max-w-[1440px] mx-auto px-2 min-[769px]:px-5 h-full flex items-center justify-between align-middle;
  }
}

.header-menu-warp-popup.explorer-menu-submenu-popup {
  @apply pt-[1px];
  .explorer-menu-vertical {
    @apply !pb-1 !pt-2 !min-w-[119px];
    .explorer-menu-item {
      @apply h-8 font-medium px-2 py-[6px] mt-0 mb-1 flex align-middle text-sm overflow-hidden;
      &:hover {
        @apply !bg-muted !text-foreground;
      }
    }
  }
}

.header-menu-warp {
  .explorer-menu-light.explorer-menu-horizontal {
    @apply border-y-0 my-auto leading-[48px];
    .explorer-menu-overflow-item,
    .explorer-menu-item,
    .explorer-menu-submenu {
      @apply px-4;
      &::after {
        @apply !border-b-0;
      }
      &:hover {
        @apply !text-foreground;
      }
      .submenu-title-wrapper {
        @apply mr-1;
      }
      .explorer-menu-title-content {
        @apply text-sm font-medium leading-[48px];
      }
      .submenu-right-arrow {
        svg {
          @apply w-4 h-4;
        }
      }
    }
    .explorer-menu-submenu-title {
      @apply h-full leading-[48px];
    }
  }

  .explorer-menu {
    @apply bg-transparent;
  }
}

.header-menu-warp-Drawer {
  .explorer-drawer-header {
    @apply !px-3 py-6 border-none !border-0;
  }
  .explorer-drawer-body {
    @apply !px-3 !pt-2 !pb-8;
  }
  .explorer-menu-inline {
    @apply border-none !border-0;

    border-inline-end: none !important;
  }
  .explorer-menu-item {
    @apply border-b border-border !rounded-none !pl-0 !py-4 !m-0 !h-[56px];
  }
  .explorer-menu-submenu {
    .explorer-menu-item {
      @apply !pl-4;
    }
  }
  .explorer-menu-submenu-title {
    @apply border-b border-border !rounded-none !pl-0 !py-4 !m-0 !h-[56px];
  }
}
